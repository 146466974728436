<template>
  <a-card :bordered="false">
    <div class="table-page-search-wrapper">
      <a-page-header
        style="border: 1px solid rgb(235, 237, 240); margin-bottom: 35px"
        title="租金减免"
        @back="handleGoBack"
      >
      </a-page-header>
    </div>

    <div style="margin-bottom: 30px">
      <a-button type="primary" icon="plus" @click="handleAdd">新建</a-button>
    </div>
    <div>
      <a-descriptions bordered v-if="this.dataSource.length > 0">
        <a-descriptions-item label="减免金额">
          {{ (this.dataSource[0].reduce / 100).toFixed(2) }}
        </a-descriptions-item>
        <a-descriptions-item label="位置">
          {{ this.$Dictionaries.location.find((item) => !!item[this.dataSource[0].location])[this.dataSource[0].location] }}
        </a-descriptions-item>
        <a-descriptions-item label="所有人">
          {{ this.$Dictionaries.electricity_meter_property_owner[this.dataSource[0].property_owner] }}
        </a-descriptions-item>
      </a-descriptions>
    </div>

    <create-form
      ref="createModal"
      :visible="visible"
      :loading="confirmLoading"
      :model="mdl"
      @cancel="handleAddCancel"
      @ok="handleOk"
    />
  </a-card>
</template>

<script>
import {
  rent_bill_reduce_list,
  rent_bill_reduce_rent_bill_create
} from '@/api/rent_bill'
import { Dictionaries } from '@/common/AllConstant'
import CreateForm from '../modules/CreateForm'

export default {
  name: 'TableList',
  components: {
    CreateForm
  },
  data () {
    return {
      pageNum: 1,
      dataSource: [],
      dictionaries: Dictionaries,
      // 创建窗口控制
      visible: false,
      mdl: {}
    }
  },
  mounted () {
    this.loadData()
  },
  methods: {
    loadData () {
      rent_bill_reduce_list(this.$route.params.id)
        .then(res => {
          console.log(res.data, '我是数据列表@')
          this.dataSource = res.data.entries
        })
    },
    handleGoBack () {
      this.$router.go(-1)
    },
    handleAdd () {
      this.mdl = null
      this.visible = true
    },
    handleAddCancel () {
      this.visible = false
      const form = this.$refs.createModal.form
      form.resetFields() // 清理表单数据（可不做）
    },
    handleOk () {
      const form = this.$refs.createModal.form
      this.confirmLoading = true
      form.validateFields((errors, values) => {
        if (!errors) {
          console.log('values', values)
            // 新增
          values['reduce'] = Number(Math.round(values['reduce'] * 100))
          rent_bill_reduce_rent_bill_create(this.$route.params.id, this.dataSource[0].id, values).then(res => {
              this.visible = false
              this.confirmLoading = false
              // 重置表单数据
              form.resetFields()
              // 刷新表格
            this.loadData()

            console.log('删除成功------')
            }).finally(() => {
            this.confirmLoading = false
          })
        } else {
          this.confirmLoading = false
        }
      })
    }
  }
}
</script>
